import Config from 'config';
import fillString from './fillString';

function shareOnFacebook(hash) {
  const { facebookId } = Config.home;
  var windowOptions = 'toolbar=no,location=no,status=no,menubar=no,' +
    'scrollbars=yes,resizable=yes,width=${width},height=${height}';
  window.open(`https://www.facebook.com/dialog/share?app_id=${facebookId}&href=${window.location.origin}/p/${hash}`,
    'shareFb', fillString(windowOptions, {
      width: 300, height: 360
    }));
}

export default shareOnFacebook;
