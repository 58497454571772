import { createLogic } from 'redux-logic';
import { Api } from 'shared/api.js';

export const name = 'explore';

const getState = state => state[name];

/*
 * TYPES
 */

const prefix = `pages/${name}/`;

const FETCH_PRESENTATIONS = `${prefix}FETCH_PRESENTATIONS`;
const FETCH_PRESENTATIONS_SUCCESS = `${prefix}FETCH_PRESENTATIONS_SUCCESS`;
const FETCH_PRESENTATIONS_CANCEL = `${prefix}FETCH_PRESENTATIONS_CANCEL`;

const FETCH_CATEGORIES = `${prefix}FETCH_CATEGORIES`;
const FETCH_CATEGORIES_SUCCESS = `${prefix}FETCH_CATEGORIES_SUCCESS`;
const FETCH_CATEGORIES_CANCEL = `${prefix}FETCH_CATEGORIES_CANCEL`;

/*
 * ACTIONS
 */

const fetchCategories = options => ({
  type: FETCH_CATEGORIES,
  payload: {
    url: Api.presentations.categories,
    method: 'GET',
    ...options
  },
});

const fetchCategoriesSuccess = data => ({
  type: FETCH_CATEGORIES_SUCCESS,
  data,
});

const fetchPresentationsSearch = options => ({
  type: FETCH_PRESENTATIONS,
  payload: {
    url: Api.presentations.search,
    method: 'GET',
    ...options
  },
});

const fetchPresentationsGet = options => ({
  type: FETCH_PRESENTATIONS,
  payload: {
    url: Api.presentations.get,
    method: 'GET',
    ...options
  },
});

const fetchPresentationsProfile = options => {
  options.sort = `&sort=${options.sort}`;
  if (options.categoryIds) {
    options.categoryIds = `&categoryIds=${options.categoryIds}`;
  }
  return {
    type: FETCH_PRESENTATIONS,
    payload: {
      url: Api.presentations.profile,
      method: 'GET',
      ...options
    },
  };
};

const fetchPresentationsSuccess = data => ({
  type: FETCH_PRESENTATIONS_SUCCESS,
  data,
});

/*
 * REDUCER
 */

const initialState = {
  isLoading: false,
  presentations: [],
  categories: [],
  metaData: {}
};

const reducer = (state = initialState, action) => {
  const actions = {
    [FETCH_CATEGORIES]: () => ({
      ...state,
      isLoading: true
    }),
    [FETCH_CATEGORIES_SUCCESS]: () => {
      console.log('action',action);
      return ({
      ...state,
      categories: [...action.data.groups],
      isLoading: false
    })},
    [FETCH_PRESENTATIONS]: () => ({
      ...state,
      isLoading: true
    }),
    [FETCH_PRESENTATIONS_SUCCESS]: () => ({
      ...state,
      presentations: [...action.data.items],
      metaData: {...action.data.metaData},
      isLoading: false
    }),
  };

  return (actions[action.type] && actions[action.type]()) || state;
};

/*
 * LOGIC
 */

const fetchCategoriesLogic = createLogic({
  type: [FETCH_CATEGORIES],
  cancelType: [FETCH_CATEGORIES_CANCEL],
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable({url: payload.url}, cancelled$)
      .then(({ data }) => {
        console.log('data',data);
        return fetchCategoriesSuccess(data)});
  },
});

const fetchPresentationsLogic = createLogic({
  type: [FETCH_PRESENTATIONS],
  cancelType: [FETCH_PRESENTATIONS_CANCEL],
  process({ action: { payload }, httpClient, cancelled$ }) {
    let { text, sort, categoryIds, pageNum, pageSize } = payload,
      apiUrl = payload.url,
      params = [];

    if (text) {
      params.push('text=' + text);
    }
    if(sort) {
      apiUrl += sort;
    }
    if(categoryIds) {
      apiUrl += categoryIds;
    }

    apiUrl += (apiUrl.indexOf('?') === -1 ? '?' : '&');

    if (pageNum) {
      params.push('pageNum=' + pageNum);
    }
    if (pageSize) {
      params.push('pageSize=' + pageSize);
    }
    apiUrl += params.join('&');

    return httpClient.cancellable({url: apiUrl}, cancelled$)
      .then(({ data }) => fetchPresentationsSuccess(data));
  },
});

/*
 * SELECTORS
 */

const getCategories = state => getState(state).categories;

const getPresentations = state => getState(state).presentations;

const getMetadata = state => getState(state).metaData;

const isLoading = state => getState(state).isLoading;

/*
 * EXPORTS
 */

export default reducer;

export const actions = {
  fetchCategories,
  fetchPresentationsSearch,
  fetchPresentationsProfile,
  fetchPresentationsGet,
};

export const types = {
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_CANCEL,
  FETCH_PRESENTATIONS,
  FETCH_PRESENTATIONS_SUCCESS,
  FETCH_PRESENTATIONS_CANCEL,
};

export const logic = {
  fetchPresentationsLogic,
  fetchCategoriesLogic,
};

export const selectors = {
  getCategories,
  getPresentations,
  getMetadata,
  isLoading
};
