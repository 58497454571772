import {createStore, applyMiddleware, compose} from 'redux';
import {createLogicMiddleware} from 'redux-logic';
import createHttpClient from './services/http-client';
import Prismic from 'prismic-javascript';
import rootReducer from './reducer';
import logic from './logic';

const preloadedState = window.__PRELOADED_STATE__;

delete window.__PRELOADED_STATE__;
export default function configureStore() {
  const logicMiddleware = createLogicMiddleware(logic);
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(rootReducer, preloadedState, composeEnhancers(
    applyMiddleware(
      logicMiddleware
    )
  ));

  logicMiddleware.addDeps({httpClient: createHttpClient(store, {withCredentials: true}), prismic: Prismic});

  return store;
}
